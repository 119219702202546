@import '../styles/colors';
@import '../styles/typography';
@import '../styles/media_queries';

.MemberUpdateList {
  list-style: none;
  margin: 0;
  padding: 0;

  .update {
    display: flex;
    margin: 0 0 1.5rem;
    position: relative;

    &:last-child .update-date {
      border-left-color: transparent;
    }
  }

  &.editable {
    .update {
      &:last-child .update-date:after {
        background-image: linear-gradient(
          to bottom,
          rgba($light-gray, 0),
          rgba($light-gray, 1)
        );
      }

      .edit-controls {
        align-items: center;
        background-image: radial-gradient(
          at center,
          rgba($tan, 1) 0%,
          rgba($tan, 1) 10%,
          rgba($tan, 0.65) 100%);
        border-radius: 8px;
        bottom: 0;
        display: flex;
        justify-content: center;
        justify-content: center;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.3s ease opacity;
        z-index: 1;

        .Button {
          flex: 1;
          margin: 0.75rem;
          max-width: 15rem;

          &.red-line:not(:hover) {
            background-color: $tan;
          }
        }
      }

      &:hover {
        .edit-controls {
          opacity: 1;
        }
      }
    }
  }

  .update-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .update-date {
    border-left: 4px solid $tan;
    border-top: 4px solid $tan;
    color: rgba($brown, 0.5);
    flex-shrink: 0;
    margin: 1rem 0 -2.5rem 1rem;
    padding: 0.5rem;
    text-align: center;
    width: 8rem;

    &:before {
      background-color: $tan;
      border-radius: 0.75rem;
      content: ' ';
      height: 1.5rem;
      left: 0.25rem;
      position: absolute;
      top: 0.25rem;
      transform: translate(2px, 2px);
      width: 1.5rem;
    }
  }

  .update-content {
    background-color: $tan;
    border-radius: 8px;
    flex: 1;
    padding: 0.75rem 1.5rem;
    position: relative;

    p {
      margin-bottom: 1rem;
    }

    h6 {
      color: rgba($brown, 0.5);
      display: flex;
      margin: 0;

      svg {
        margin-right: 0.5rem;
        transform: translateY(1px);
      }
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .MemberUpdateList {
    &.editable .update .edit-controls {
      align-items: flex-end;
      background-image: linear-gradient(
          to bottom,
          rgba($tan, 0.5) 0%,
          rgba($tan, 1) 100%);
      opacity: 1;
    }

    .update {
      margin-bottom: 1rem;

      &:last-child .update-date {
        border-left-color: $tan;
        bottom: 0;
      }
    }

    .update-footer {
      align-items: flex-start;
      flex-direction: column-reverse;

      .Button {
        margin-bottom: 1.5rem;
        padding: 0;
      }
    }

    .update-date {
      border-top: 0;
      bottom: -2rem;
      left: 3rem;
      margin: 0 0 0 -0.5rem;
      padding-top: 0;
      position: absolute;
      top: 1rem;
      z-index: 1;

      &::before {
        left: -1rem;
        top: -0.25rem;
      }
    }
    .update-content {
      margin-top: 3.5rem;
      position: relative;
      z-index: 2;
    }
  }
}
