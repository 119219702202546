@import 'media_queries';

.content {
  max-width: 72rem;
  margin: 0 auto;
  padding: 0 1rem;
}

section {
  margin-top: -3rem;
  padding: 6rem 0 3rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.cardlist {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;

  .cardlist-item {
    padding: 1.5rem;
    width: 25%;
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .cardlist {
    margin: 0 -1rem;

    .cardlist-item {
      width: 33.33%;
      padding: 1rem;
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  section {
    padding: 1.5rem 0;

    &:first-of-type {
      padding-top: 3rem;
    }
  }

  .cardlist {
    flex-direction: column;

    .cardlist-item {
      width: 100%;
    }
  }
}
