@import '../styles/colors';
@import '../styles/typography';
@import '../styles/media_queries';

.VentureFirm {
  background-color: $white;
  box-shadow: 0 0 24px rgba($black, 0.1);
  display: flex;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .firm-description {
    flex: 1;
    font-size: 1rem;
    padding-left: 1.5rem;

    i {
      background-position: center left;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 3rem;
      margin-bottom: 1rem;
      max-width: 15rem;
      width: 100%;
    }
  }

  .firm-partners {
    flex: 2;
    margin: -1.5rem 0 -1.5rem -1.5rem;
  }
}

.Partner {
  border-bottom: 1px solid $tan;
  display: flex;
  flex-shrink: 0;
  padding: 1.5rem;

  &:last-child:not(:first-child) {
    border-bottom: 0;
  }

  .more-icon {
    align-self: center;
    opacity: 0.5;
    transition: 0.3s linear all;
  }

  &:hover {
    background-color: $light-gray;
    cursor: pointer;

    .more-icon {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }
  }

  .left-side {
    flex: 1;
    flex-shrink: 0;
    padding-right: 1.5rem;
    width: 50%;
  }

  .focus-areas {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 -0.5rem;
    padding: 0;

    a {
      display: flex;
      opacity: 0.5;
      transition: 0.3s ease opacity;

      &:hover {
        opacity: 1;
      }
    }

    .social-text {
      line-height: 1;
      padding-left: 0.25rem;
    }
  }

  .partner-header {
    align-items: center;
    display: flex;

    i {
      background-color: $brown;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      flex-shrink: 0;
      height: 3.5rem;
      margin-right: 1rem;
      pointer-events: none;
      width: 3.5rem;
    }

    h3 {
      margin: -0.5rem 0 0rem;
    }

    .social-links {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        display: flex;
        opacity: 0.5;
        transition: 0.3s ease opacity;

        &:hover {
          opacity: 1;
        }
      }

      li {
        align-items: center;
        display: flex;
        margin-right: 1rem;

        .social-text {
          line-height: 1;
          padding-left: 0.25rem;
        }
      }
    }
  }

  .bio {
    line-height: 1.5em;
    margin-right: -1em;
    max-height: (1.5em * 5);
    overflow: hidden;
    padding-right: 1em;
    position: relative;
    text-align: justify;

    &:before {
      bottom: 0;
      content: '...';
      position: absolute;
      right: 0;
      transform: translateX(-4px);
    }

    &:after {
      background: white;
      content: '';
      height: 1em;
      margin-top: 0.2em;
      position: absolute;
      right: 0;
      width: 1em;
    }
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .Partner {
    &:last-child:not(:first-child) {
      border-bottom: 1px solid $tan;
    }
  }

  .VentureFirm {
    flex-direction: column;
    padding-top: 0;

    .firm-description {
      display: flex;
      justify-content: space-between;
      padding-left: 0;
      padding-right: 0;

      i {
        margin: 0;
      }

      p {
        padding-left: 0.5rem;
        width: 50%;
      }
    }

    .firm-partners {
      margin: 0 -1.5rem 1.5rem;
    }
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .Partner {
    flex-direction: column;
    position: relative;

    .more-icon {
      margin-top: -1rem;
      position: absolute;
      right: 1rem;
      top: 50%;
      transition: 0.3s linear all;
    }

    .left-side {
      padding: 0 0 1rem;
      width: 100%;
    }
  }

  .VentureFirm {
    .firm-description {
      display: block;

      i {
        margin-bottom: 1rem;
      }

      p {
        padding-left: 0;
        width: auto;
      }
    }
  }
}
