@import '../styles/colors';
@import '../styles/typography';
@import '../styles/media_queries';

.Chip {
  @extend %p;
  align-items: center;
  background-color: $tan;
  border-radius: 4px;
  color: rgba($brown, 0.75);
  display: flex;
  font-size: 0.875rem;
  font-weight: $regular;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  max-width: 100%;
  padding: 0.125rem 0.5rem;

  &.small {
    font-size: 0.675rem;
    line-height: 1.75;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
    padding: 0 0.5rem;
  }

  .chip-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

