@import '../styles/colors';
@import '../styles/typography';
@import '../styles/media_queries';

.Footer {
  background-color: $tan;
  padding: 6rem 0 9rem;
  color: $brown;

  .grid {
    display: flex;
    margin: 0 -1.5rem;

    > .half {
      width: 50%;
      padding: 0 1.5rem;
    }
  }

  .nav-items {
    @extend %h5;
  }

  ul {
    list-style: none;

    li {
      line-height: 1.5;
      text-align: left;

      + li {
        margin-top: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .Footer {
    padding: 2rem 0 3rem;

    .grid {
      margin: 0 -1rem;

      &:not(.m-grid) {
        flex-direction: column;

        > .half {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
