@import "../styles/colors";
@import "../styles/media_queries";

.GuidePage {
  background-color: $light-gray;
  padding-top: 6rem;

  h1,
  h2,
  h3 {
    color: $brown;
    margin: 3rem 0 1.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    color: $brown;
    font-size: 1.5rem;
    margin-top: 3rem;
  }

  p a {
    color: $green;
    text-decoration: underline rgba($green, 0);
    transform: 0.3s ease text-decoration-color;

    &:hover {
      text-decoration-color: $green;
    }
  }

  strong {
    font-weight: bold;
  }

  ul,
  ol {
    li {
      > p {
        margin: 0.5rem 0;
      }
    }
  }

  p {
    margin: 1.5rem 0;
    color: $brown;
    strong {
      opacity: 0.8;
    }
  }

  section:not(:first-of-type)::before {
    content: "* * *";
    text-align: center;
    display: block;
    height: 4.5rem;
    margin-top: -1.5rem;
    padding-bottom: 1.5rem;
  }

  .jump-to {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .jumplinks {
    .jumplink-title {
      font-weight: bold;
      font-size: 1rem;
      display: inline;
      flex: 1;
      margin: 0;
    }

    .jumplink-set {
      display: flex;
      align-items: flex-start;
      margin: 1rem 0;

      @media screen and (max-width: $phone-landscape-max) {
        flex-direction: column;
      }
    }

    ul {
      flex: 3;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        line-height: 2;
        display: inline;
        margin-right: 1rem;
        text-decoration: underline;
        transition: 0.3s ease color;

        &:hover {
          color: $green;
        }
      }
    }
  }
  .table-wrapper {
    overflow-x: scroll;
    width: calc(100% + 2rem);
    padding-bottom: 1.5rem;
    margin-bottom: -1.5rem;
    display: block;
    &::after {
      content: " ";
      width: 1rem;
    }
  }

  table {
    color: $brown;
    float: left;
    background-color: $white;
    min-width: 48rem;
    margin: 0 1rem;
    box-shadow: 0 0 24px rgba($black, 0.1);
    border-spacing: 0;

    .selected {
      background-color: $green;
    }

    tr {
      th {
        border-bottom: 1px solid $medium-gray;
        background-color: $tan;
        height: 2.5rem;
      }

      td:first-child {
        width: 25%;
        color: $brown;
        padding: 0 0.5rem;
      }
      td:nth-child(5),
      td:nth-child(9) {
        border-right: 0.125rem solid $light-gray;
      }
      td:nth-child(6),
      td:nth-child(10) {
        border-left: 0.125rem solid $light-gray;
      }
      td {
        border-right: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        height: 2.5rem;
        width: 6.25%;
      }
    }
  }

  .Footer {
    margin-top: 6rem;
  }
}
