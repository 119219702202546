@import "../styles/colors";
@import "../styles/typography";
@import "../styles/media_queries";

$subnav-topnav-space: 1.75rem;
$navbar-padding: 2.25rem;
$logo-height: 1.5rem;
$subnav-to-top: $subnav-topnav-space + $navbar-padding + $logo-height;

// Navbar.js
// SUBNAV_TO_PADDING = $subnav-topnav-space + $logo-height;

.Navbar {
  background-color: $brown;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
  color: $white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  &.logged-out {
    .nav-items {
      height: 6rem;
      padding-bottom: $navbar-padding;
    }
  }

  &.with-subnav {
    position: absolute;

    &.is-pinned {
      position: fixed;
      transform: translate3d(0, (-$logo-height - $subnav-topnav-space), 0);

      .drawer {
        opacity: 0;
      }

      .nav-items li.interact-logo {
        top: $subnav-to-top;
      }

      .sub-nav {
        top: 0;
      }
    }
  }

  .dark-bg {
    background-color: $brown;
    height: 6rem;
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 900;
  }

  .content {
    margin: 0 auto;
    max-width: 72rem;
    padding: 0 1.5rem;
    position: relative;
    z-index: 1000;

    &:last-child {
      z-index: 950;
    }
  }

  .drawer-items {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .nav-items {
    align-items: center;
    display: flex;
    height: $subnav-to-top;
    list-style: none;
    margin: 0;
    padding: $navbar-padding 0 $subnav-topnav-space;

    li {
      flex-shrink: 0;
      margin-left: 3rem;
      position: relative;

      &.interact-logo {
        position: fixed;
        top: $navbar-padding;
      }

      .light {
        opacity: 0.5;
      }

      .email {
        background-color: $white;
        box-shadow: 0 4px 16px rgba($black, 0.1);
        color: $brown;
        display: block;
        font-size: 1rem;
        letter-spacing: 0;
        opacity: 0;
        padding: 0.5rem 1rem;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 2rem;
        white-space: nowrap;
        transition: 0.2s ease opacity;
        z-index: 200;
        @extend %p;
      }

      a:hover > .email {
        opacity: 1;
      }

      &.divider {
        background-color: $white;
        height: 1px;
        width: 100%;
      }

      a {
        @extend %h5;
        text-decoration: none;
      }

      &.spacer {
        flex: 1;
      }

      &.drawer {
        margin-left: 0;
        transition: 0.2s ease opacity;
      }

      &:first-child {
        margin-left: 0;
      }

      img {
        height: $logo-height;
      }
    }
  }

  .sub-nav {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0 0 $navbar-padding;

    li {
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-left: 1.5rem;
      opacity: 1;
      transition: 0.2s ease opacity;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

body {
  padding-top: 6rem;
}

@media screen and (min-width: ($tablet-portrait-max + 1)) {
  .m-only {
    display: none;
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .Navbar {
    .sub-nav {
      li {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .Navbar {
    position: fixed;
    z-index: 1000;

    &.with-subnav,
    &.with-subnav.is-pinned {
      position: fixed;
      transform: none;

      .drawer {
        opacity: 1;
      }

      .nav-items li.interact-logo {
        top: 1rem;
      }
    }

    &.logged-out {
      .nav-items {
        height: 3rem;
        padding-bottom: 1rem;
      }
    }

    .dark-bg {
      height: 3rem;
    }

    .nav-items {
      // height: 4rem;

      .drawer {
        pointer-events: none;

        li {
          margin-left: 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          img {
            height: 0.75rem;
          }
        }
      }
    }

    .sub-nav {
      display: none;
    }

    .content {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    svg {
      height: 1rem;
    }

    .nav-items {
      height: 3rem;
      margin-left: 0;
      padding: 1rem 0;

      li {
        z-index: 300;

        &.interact-logo {
          top: 1rem;
        }

        a {
          font-size: 0.875rem;
        }

        img {
          height: 1rem;
        }
      }

      .m-background {
        display: block;
      }

      .drawer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        margin: 0;
        overflow: hidden;
        position: absolute;
        top: 3rem;
        width: 100%;
        z-index: 100;

        .drawer-items {
          align-items: flex-start;
          background-color: $brown;
          flex-direction: column;
          padding: 1rem;
          transform: translate3d(0, -3rem, 0) translate3d(0, -100%, 0);
          transition: 0.3s ease transform;

          li {
            margin: 0 0 1rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          a {
            font-size: 0.875rem;
            line-height: 1.125rem;
          }
        }
      }

      .hamburger {
        cursor: pointer;
        display: flex;
        height: 1rem;
        position: relative;
        width: 1rem;
        z-index: 200;

        .ham,
        .bur,
        .gur {
          background-color: $white;
          border-radius: 1px;
          height: 3px;
          left: 0;
          position: absolute;
          top: 50%;
          transition: 0.3s ease all;
          width: 100%;
        }

        .ham {
          transform: translate3d(0, -1.5px, 0) translate3d(0, -0.35rem, 0);
        }

        .bur {
          transform: translate3d(0, -1.5px, 0);
        }

        .gur {
          transform: translate3d(0, -1.5px, 0) translate3d(0, 0.35rem, 0);
        }
      }
    }

    &.nav-open {
      .hamburger {
        .ham {
          transform: translate3d(0, -1.5px, 0) rotate(-45deg);
        }

        .bur {
          opacity: 0;
        }

        .gur {
          transform: translate3d(0, -1.5px, 0) rotate(45deg);
        }
      }

      li.drawer {
        pointer-events: auto;

        .drawer-items {
          transform: none;
        }
      }
    }
  }
}
