@import '../styles/colors';
@import '../styles/typography';
@import '../styles/media_queries';

.EventList {
  .upcoming, .past-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem;
  }

  .past {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s linear max-height;

    &.is-open {
      + .see-more svg {
        transform: rotate(180deg);
      }
    }
  }

  .see-more {
    height: 3rem;
    margin: 2rem 0;
    background-color: $tan;
    text-align: center;
    @extend %h6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      margin-left: 1rem;
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      margin-top: -0.2rem;
      transition: 0.3s ease all;
    }
  }

  .event {
    padding: 1.5rem;
    width: 25%;

    .inner {
      box-shadow: 0 4px 16px rgba($black, 0.1);
      display: block;
    }

    .image {
      height: 7.5rem;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
      position: relative;

      .date {
        position: absolute;
        background: $tan;
        color: $brown;
        left: 0;
        bottom: 0;
        font-size: 0.875rem;
        font-weight: $semibold;
        font-style: normal;
        padding: 0.5rem 1rem;
      }
    }

    .text {
      display: flex;
      align-items: center;
      padding: 1rem;
      background-color: $white;
    }

    h4 {
      margin-left: 1rem
    }
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .EventList {
    .upcoming, .past-inner {
      .event {
        width: 33%;
      }
    }
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .EventList {
    .upcoming, .past-inner {
      .event {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .EventList {
    .upcoming, .past-inner {
      flex-direction: column;
      margin: 0 -1rem;

      .event {
        width: 100%;
      }
    }
  }
}
