@import '../styles/colors';
@import '../styles/typography';
@import '../styles/media_queries';

.searchable-table.disabled {
  position: relative;

  .scrim {
    background-image: radial-gradient(
      at center,
      rgba($tan, 1) 0%,
      rgba($tan, 1) 10%,
      rgba($tan, 0.65) 100%);
    background-position: center center;
    background-size: cover;
    bottom: 0;
    cursor: not-allowed;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }

  .warning {
    left: 50%;
    max-width: 25rem;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 101;

    p {
      @extend %h3;
      line-height: 1.5;
      margin-bottom: 0;
      text-align: center;
    }
  }
}

.table {
  .name {
    flex: 3;
  }

  .location, .interests, .skills, .role, .team {
    flex: 2;
  }

  .name {
    align-items: center;
    display: flex;
    min-width: 12rem;
  }

  .chips {
    display: flex;
    flex-grow: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    &::after {
      background: linear-gradient(
        to right,
        rgba($white, 0),
        $white
        );
      bottom: 0;
      content: ' ';
      position: absolute;
      right: 0;
      top: 0;
      width: 1.5rem;
    }

    &:last-child {
      margin-bottom: -0.25rem;
    }
  }

  .table-row:hover .chips::after {
    background: linear-gradient(
      to right,
      rgba($light-gray, 0),
      $light-gray
      );
  }

  i, .headshot-placeholder {
    background-color: $brown;
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
    height: 2rem;
    margin-right: 0.5rem;
    user-select: none;
    width: 2rem;
  }

  i {
    background-position: center;
    background-size: cover;
  }

  .name-and-role {
    display: block;
    padding-right: 1rem;
    width: 100%;

    .m-name {
      display: block;
      font-weight: $semibold;
      line-height: 1.125;
      overflow: hidden;
      padding-right: 1.5rem;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .m-role {
      display: block;
      line-height: 1.125;
      overflow: hidden;
      padding-right: 1.5rem;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .headshot-placeholder {
    @extend %h5;
    color: $white;
    line-height: 2rem;
    text-align: center;
  }

  .cohort {
    flex: 0.25;
    min-width: 7.5rem;
  }

  .links {
    align-items: center;
    display: flex;
    width: 3rem;

    .link-grid {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5rem 0;
      width: 100%;

      a {
        width: 50%;
      }
    }

    svg {
      opacity: 0.5;
    }

    a, button {
      flex-shrink: 0;
      width: 0.5rem;
    }
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .searchable-table.disabled {

    .scrim {
      background-image: radial-gradient(
        at center,
        rgba($tan, 1) 0%,
        rgba($tan, 0.95) 50%,
        rgba($tan, 0.75) 100%);
      bottom: 0;
      cursor: not-allowed;
      left: 0;
      position: absolute;
      right: -1rem;
      top: 2rem;
      z-index: 100;
    }
  }
}
