@import '../../styles/colors';
@import '../../styles/typography';

.IconCard {
  .inner {
    box-shadow: 0 4px 16px rgba($black, 0.1);
    display: block;
  }

  .image {
    height: 7.5rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    position: relative;

    .date {
      position: absolute;
      background: $tan;
      color: $brown;
      left: 0;
      bottom: 0;
      font-size: 0.875rem;
      font-weight: $semibold;
      font-style: normal;
      padding: 0.5rem 1rem;
    }
  }

  .text {
    align-items: center;
    display: flex;
    padding: 1rem;
    background-color: $white;
  }

  h4 {
    margin-left: 1rem
  }
}
