@import '../styles/colors';
@import '../styles/typography';

.TeamList {
  .name, .role, .current, .team, .location {
    flex: 1;
  }

  .name {
    align-items: center;
    display: flex;
    min-width: 12rem;
  }

  .cohort {
    flex: 0.25;
    min-width: 7.5rem;
  }

  .headshot-placeholder {
    @extend %h6;
    color: $white;
    text-align: center;
    line-height: 1.5rem;
  }

  .links {
    width: 6rem;
    display: flex;

    svg {
      opacity: 0.5;
    }

    a, button {
      display: inline-block;
      margin-right: 0.75rem;
      width: 0.75rem;
      flex-shrink: 0;
    }
  }

  i, .headshot-placeholder {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
    background-color: $brown;
    user-select: none;
  }

  i {
    background-size: cover;
    background-position: center;
  }
}
