@import '../styles/colors';
@import '../styles/media_queries';


.MemberHome {
  background-color: $light-gray;
  padding-top: 6rem;

  h1, h2, h3 {
    color: $brown;
    margin: 3rem 0 1.5rem;
  }

  .Footer {
    margin-top: 6rem;
  }

  .description {
    display: flex;
    justify-content: space-between;
    margin: 3.375rem -1.5rem 3rem;

    .call-to-action {
      padding: 0 1.5rem;
      width: 25%;
    }

    .Button {
      width: 100%;
    }

    p {
      flex: 1;
      margin-top: -0.5rem;
      max-width: 45rem;
      padding: 0 1.5rem;
    }

    p > a {
      text-decoration: underline;
      transition: 0.2s linear color;

      &:hover {
        color: $green;
      }
    }
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .MemberHome {
    .description {
      margin: 1rem -1rem;

      p, .call-to-action {
        padding: 0 1rem;
      }

      .call-to-action {
        width: 33%;
      }
    }
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .MemberHome {
    padding-top: 0;

    .description {
      flex-direction: column;
      margin: 1rem -1rem;

      p {
        padding: 0 1rem;
      }

      .call-to-action {
        margin-top: 1rem;
        padding: 0 1rem;
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .MemberHome {
    .description {
      flex-direction: column;
      margin: 1rem -1rem;

      p {
        padding: 0 1rem;
      }

      .call-to-action {
        width: 100%;
      }
    }
  }
}
