@import '../../styles/colors';
@import '../../styles/media_queries';

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 6rem, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  &.modal-open {
    overflow: hidden;

    .Modal {
      pointer-events: auto;
    }
  }
}

.Modal {
  height: 100%;
  left: 0;
  overflow: scroll;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .modal-scrim {
    animation: fade-in 0.3s ease;
    background-color: rgba($black, 0.65);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: 0.3s ease opacity;
  }

  .modal-window {
    animation: fade-in-up 0.3s ease;
    background-color: $white;
    margin: 6rem auto;
    max-width: 70rem;
    position: relative;
    transition: 0.3s ease all;
  }

  .close-button {
    border-radius: 50%;
    box-sizing: content-box;
    cursor: pointer;
    font-size: 1.5rem;
    overflow: hidden;
    padding: 0.5rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;

    path {
      transform-origin: center center;
      transition: 0.3s ease transform;
    }

    &:hover path {
      transform: rotate(90deg);
    }
  }

  &.closing {
    .modal-scrim {
      opacity: 0;
    }

    .modal-window {
      opacity: 0;
      transform: translate3d(0, 6rem, 0);
    }
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .Modal {
    .modal-window {
      margin: 0;
    }

    .modal-content {
      padding-top: 3rem;
    }

    .top-bar {
      align-items: center;
      background-color: $tan;
      display: flex;
      height: 3rem;
      justify-content: flex-end;
      left: 0;
      padding: 0 0.5rem;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 15000;

      .close-button {
        display: relative;
        right: auto;
        top: auto;
      }
    }
  }
}
