@import '../styles/colors';
@import '../styles/media_queries';

.ProfilePage {
  background-color: $light-gray;
  padding-top: 6rem;

  > section {
    padding-bottom: 6rem;
  }

  h2 .Button {
    display: inline-flex;
    line-height: 3rem;
    margin-left: 0.75rem;
    transform: translateY(-0.25rem);
  }

  .form-wrapper {
    align-items: flex-start;
    display: flex;

    .ProfilePictureEditor {
      margin-right: 3rem;
      padding: 3rem 0 0;
      width: 10rem;
    }

    form {
      flex: 1;
    }
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .ProfilePage .form-wrapper form {
    flex: 3;
  }
}
@media screen and (max-width: $tablet-portrait-max) {
  .ProfilePage {
    padding-top: 3rem;

    h2 .Button span {
      display: none;
    }

    .form-wrapper {
      flex-direction: column;

      .ProfilePictureEditor {
        margin: 0 auto;
        max-width: 10rem;
        width: 100%;
      }
    }
  }
}
