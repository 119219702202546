@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/media_queries";

.Modal {
  .interests {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 -0.25rem;
    padding: 0;
  }

  .modal-content .header {
    align-items: flex-start;

    h2 {
      padding-top: 0.75rem;
      align-items: center;
    }
  }

  .collaborators {
    display: flex;
    flex-wrap: wrap;
    margin: 0.75rem 0;
    padding: 0;
  }

  .cohort-and-location {
    display: flex;

    p {
      line-height: 1.5;
    }

    > div {
      padding-right: 1.5rem;
      flex: 1;
    }
  }

  .contact-instruction {
    margin-top: 1.5rem;
  }

  .self-notice {
    align-items: center;
    display: inline-flex;

    .Button > span {
      line-height: 1rem;
    }
  }

  .full-name {
    margin-right: 1rem;
  }

  .pronouns {
    align-items: center;
    display: inline-flex;
    margin-right: 1rem;

    h5 {
      margin: 0;
    }
  }

  .team {
    line-height: 1rem;
    margin: 0.25rem 1rem 0.25rem 0;
  }

  .link-to-doc {
    align-items: center;
    display: inline-flex;
    .Button {
      line-height: 0.5rem;
      color: $brown;
      margin: 0.25rem 1rem 0.25rem 0;
      max-height: 1.5rem;
      opacity: 50%;
    }
    .Button:hover {
      background-color: transparent;
      text-decoration: none;
      opacity: 100%;
    }
  }

  .name-pronunciation {
    align-items: center;
    display: inline-flex;
    margin-right: 1rem;
    margin: 0.25rem 1rem 0.25rem 0;
    position: relative;

    svg {
      margin-right: 0.75rem;
      font-size: 1.125rem;
      color: $brown;
    }

    .tooltip {
      @extend %p-small;
      background-color: $white;
      box-shadow: 0 4px 16px rgba($black, 0.1);
      left: 50%;
      opacity: 0;
      padding: 0.5rem 1rem;
      pointer-events: none;
      position: absolute;
      top: 100%;
      transform: translate(-50%, 1rem);
      transition: 0.3s ease opacity;
      white-space: nowrap;
      z-index: 100;
    }

    &:hover .tooltip {
      opacity: 1;
    }
  }

  .header-text {
    flex: 1;

    h2, h4 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .headshot-placeholder {
    @extend %h4;
    background-color: $brown;
    border-radius: 50%;
    color: $white;
    font-size: 2rem;
    line-height: 4rem;
    margin-right: 1.5rem;
    text-align: center;
    user-select: none;
    width: 4rem;
  }

  .collaborators-list {
    margin: 0 0 -0.5rem;
    padding: 0;
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .Modal {
    .self-notice {
      align-items: center;
      display: inline-flex;
      position: fixed;
      top: 0.675rem;
      left: 1.5rem;
      z-index: 100000;

      .Button {
        font-size: 1rem;
        height: 1.75rem;
        padding: 0;
        color: $green;
      }
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .Modal {
    .full-name {
      padding-right: 0.5rem;
    }

    .cohort-and-location {
      > div:last-child {
        flex: 0;
      }
    }

    .name-pronunciation {
      position: absolute;
      right: -0.5rem;
      top: 5.25rem;

      .tooltip {
        right: 3.5rem;
        top: 50%;
        left: auto;
        width: auto;
        transform: translate(1rem, -50%);
      }
    }

    .pronouns h5 {
      font-size: 0.875rem;
      letter-spacing: 1px;
    }

    .modal-window .content-area .primary-area {
      margin-right: 0;
    }
  }
}
