@import '../styles/colors';
@import '../styles/typography';
@import '../styles/media_queries';

.MemberChip {
  background-color: $light-gray;
  border-radius: 1rem;
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: 0 0.75rem 0 0;
  white-space: nowrap;

  i {
    background-color: $brown;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    height: 2rem;
    margin-right: 0.5rem;
    width: 2rem;
  }
}
