@import '../../styles/colors';
@import '../../styles/typography';
@import '../../styles/media_queries';

.Modal .modal-window {
  flex-shrink: 0;
  padding: 3rem;

  h3 {
    margin-bottom: 0.5rem;
  }

  .secondary-area ul {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 1.5rem;
    padding: 0.75rem 0 0;

    a {
      display: flex;
      opacity: 0.5;
      transition: 0.3s ease opacity;

      &:hover {
        opacity: 1;
      }
    }

    .social-text {
      line-height: 1;
      padding-left: 0.25rem;
    }
  }

  .header {
    align-items: center;
    display: flex;

    i {
      background-color: $brown;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      flex-shrink: 0;
      height: 6rem;
      margin-right: 1.5rem;
      margin-right: 1.5rem;
      pointer-events: none;
      width: 6rem;
    }

    h2 {
      line-height: 1;
      margin: 0 0 0.5rem;
    }

    h3 {
      line-height: 1;
      margin: 0;
      opacity: 0.5;
    }
  }

  h3 + .small {
    margin-top: -1.5rem;
  }

  .welcome-message {
    background-color: $tan;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 0;
    margin-top: 1.5rem;
    max-width: 75%;
    padding: 0.75rem 1.5rem;
    position: relative;

    &:before {
      border-bottom: 1.1rem solid $tan;
      border-left: 1.1rem solid transparent;
      border-right: 1.1rem solid transparent;
      bottom: 100%;
      content: ' ';
      display: block;
      height: 0.5rem;
      left: 1.9rem;
      position: absolute;
      width: 0;
    }
  }

  .content-area {
    display: flex;

    .secondary-area {
      flex: 1;
    }

    .primary-area {
      flex: 2;
      margin-right: 1.5rem;
    }
  }

  p {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .endorsements {
    margin: 0;
    padding: 0;

    .endorsement {
      display: flex;
      list-style: none;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      .endorser {
        width: 12rem;

        h4 {
          flex: 0;
          padding-right: 1rem;
        }
      }

      > p {
        background-color: $tan;
        border-radius: 6px;
        flex: 1;
        padding: 0.5rem 1rem;
        position: relative;

        &:before {
          border-bottom: 0.6rem solid transparent;
          border-right: 0.6rem solid $tan;
          border-top: 0.6rem solid transparent;
          content: ' ';
          display: block;
          height: 0.5rem;
          position: absolute;
          right: 100%;
          top: 0.6rem;
          width: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .Modal .modal-window {
    padding: 1.5rem 1.5rem 3rem;

    .content-area {
      flex-direction: column;
    }

    .header {
      i {
        height: 4rem;
        width: 4rem;
      }

      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1.25rem;
      }
    }

    .primary-area {
      margin-right: 0;
    }

    .welcome-message {
      max-width: 100%;

      &:before {
        left: 0.9rem;
      }
    }
    .endorsements {
      .endorsement {
        flex-direction: column;

        h4 {
          padding-bottom: 1rem;
          padding-left: 0;
          padding-right: 0;
          text-align: left;
        }

        p {
          &:before {
            border-bottom: 0.6rem solid $tan;
            border-left: 0.6rem solid transparent;
            border-right: 0.6rem solid transparent;
            bottom: 100%;
            content: ' ';
            display: block;
            height: 0.5rem;
            left: 0.6rem;
            position: absolute;
            right: auto;
            top: auto;
            width: 0;
          }
        }
      }
    }
  }

}
