@import './styles/colors';
@import './styles/typography';
@import './styles/fonts';
@import './styles/layout';
@import './styles/forms';
@import './styles/Spinner';

body, html {
  font-family: $sans;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@import './styles/responsive';

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  color: inherit;
}

h1, h2, h3, h4 {
  margin: 0;
}

p {
  margin: 0;
}

.root-loading {
  align-items: center;
  background-color: $brown;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

.placeholder {
  align-items: center;
  display: flex;
  height: 20rem;
  justify-content: center;
  width: 100%;
}
