@import 'colors';

form {
  max-width: 45rem;
  padding: 1.5rem 0;
  width: 100%;

  h3 {
    padding-top: 1.5rem;
  }
}

.form-control-set {
  display: flex;
  margin: 1.5rem 0;
  width: 100%;

  .form-control {
    flex: 1;
    margin: 0 3rem 0 0;

    &.small {
      flex: 0;
      min-width: 8rem;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.form-control {
  margin: 1.5rem 0;
  position: relative;
  width: 100%;

  .char-count {
    @extend %h6;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;

    &.over-limit {
      color: $red;
      opacity: 1;
    }
  }

  &.has-error {
    input, textarea, input:focus, textarea:focus {
      border-color: $red !important;
    }

    .error {
      color: $red;
      margin-top: 0.5rem;
    }
  }

  .multiselect-wrapper > div > div {
    overflow: hidden;
  }

  .radio-options {
    list-style: none;
    margin: 0;
    padding: 0;

    .radio-option {
      margin: 0;
      padding: 0.75rem 0;
    }
  }

  .checkbox-options {
    list-style: none;
    margin: 0 0 0 1.5rem;
    padding: 0;

    .checkbox-option {
      margin: 0;
      padding: 0;
    }
  }

  .radio-options .radio-option, &.checkbox {
    input, textarea {
      display: none;
      margin: 0;
      padding: 0;
    }

    label {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin: 0;

      .text {
        .title {
          display: block;

          a {
            text-decoration: underline;
            transition: 0.3s ease opacity;

            &:hover {
              opacity: 0.5;
            }
          }
        }

        .description {
          @extend %p;
          font-size: 1rem;
          line-height: 1.5;
        }
      }
    }

    svg {
      margin-right: 0.75rem;
    }
  }

  .error-message {
    @extend %p;
    align-items: center;
    align-self: center;
    color: $red;
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0 0;
    padding: 0.5rem 1rem;

    svg {
      cursor: pointer;
    }
  }

  p {
    border: 1px solid $medium-gray;
    cursor: not-allowed;
    padding: 0.25rem 1rem;
    user-select: none;
  }

  input, textarea {
    width: 100%;
  }

  .optionContainer li {
    background-color: $white;

    &.highlight, &:hover {
      background-color: $light-gray;
    }
  }

  label {
    @extend %h6;
    display: block;
    margin-bottom: 0.5rem;
  }

  .required:after {
    content: '*';
    opacity: 0.5;
  }
}

input, textarea {
  appearance: none;
  outline: none;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  resize: none;
}

input[type=submit] {
  cursor: pointer;
  margin-top: 1.5rem;
  min-width: 10rem;
}

input[type=text], input[type=email], textarea {
  @extend %p;
  border: 1px solid $medium-gray;
  margin: 0;
  padding: 0.25rem 1rem;

  &:active, &:focus {
    border-color: $green;
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .form-control-set {
    flex-direction: column;
    .form-control {
      margin: 0 0 1.5rem;
    }
  }

  .radio-options .radio-option svg,
  .checkbox-options .checkbox-option svg,
  .checkbox svg {
    flex-shrink: 0;
    font-size: 1.25rem;
    height: 1.25rem;
    width: 1.25rem;
  }
}
