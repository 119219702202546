@import '../styles/colors';
@import '../styles/media_queries';
@import '../styles/typography';

.ProfileOnboardingJourney {
  margin: 0;
  padding: 1.5rem;

  &:not(.condensed) {
    background-color: $tan;
    margin: 1.5rem 0 0;
  }

  h6 {
    margin: 0.25rem 0 0;
    text-align: center;
  }

  p {
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  .progress-bar {
    background-color: $medium-gray;
    border-radius: 0.5rem;
    height: 1rem;
    width: 100%;

    .progress {
      background-color:$green;
      border-radius: 0.5rem;
      height: 1rem;
      min-width: 1.5rem;
    }
  }

  .next-action {
    @extend %h4;
    display: inline-block;
    min-width: 15rem;
    padding-top: 0.5rem;
    white-space: nowrap;
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .ProfileOnboardingJourney {
    .next-action {
      margin-left: 0 !important;
      text-align: left !important;
      transform: none !important;
      white-space: normal;
    }
  }
}
