@import 'typography';

@font-face {
  font-family: 'Camber';
  font-weight: $regular;
  src: url(../fonts/Camber-Rg.eot) format('eot'),
       url(../fonts/Camber-Rg.woff) format('woff'),
       url(../fonts/Camber-Rg.ttf) format('truetype');
}

@font-face {
  font-family: 'Camber';
  font-weight: $semibold;
  src: url(../fonts/Camber-Sb.eot) format('eot'),
       url(../fonts/Camber-Sb.woff) format('woff'),
       url(../fonts/Camber-Sb.ttf) format('truetype');
}

@font-face {
  font-family: 'Kepler Std';
  font-weight: $regular;
  src: url(../fonts/KeplerStd-Regular.otf) format('otf');
}

@font-face {
  font-family: 'Kepler Std';
  font-weight: $medium;
  src: url(../fonts/KeplerStd-Medium.otf) format('otf');
}

@font-face {
  font-family: 'Kepler Std';
  font-weight: $bold;
  src: url(../fonts/KeplerStd-Bold.otf) format('otf');
}
