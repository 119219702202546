@import '../styles/colors';
@import '../styles/typography';

.Button {
  @extend %h5;
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: center;
  line-height: 4rem;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: 0.2s ease all;
  user-select: none;

  &.green-line {
    border: 2px solid $green;
    color: $green;
  }

  &.red-line {
    border: 2px solid $red;
    color: $red;

    &:hover {
      background-color: darken($red, 20%);;
      border-color: darken($red, 20%);;
    }
  }

  &.green-text {
    background-color: transparent;
    border: 0;
    color: $green;
    height: 1.5rem;
    line-height: 1.5rem;

    &:hover {
      background-color: transparent;
      color: darken($green, 20%);
    }

    &.hidden-text {
      color: transparent;
      line-height: 1rem;
      padding: 0 0.5rem;

      svg {
        color: $green;
      }

      &:hover {
        color: $green;
      }
    }
  }

  &.with-arrow {
    @extend %h6;

    svg {
      line-height: 1rem;
      margin: 0 0 0 0.5rem;
      transition: 0.3s ease transform;
    }

    &:hover svg {
      transform: translate3d(0.25rem, 0, 0);
    }
  }

  &.green {
    background-color: $green;
    border: 0;
    color: $white;

    &[disabled] {
      background-color: $medium-gray;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &.small {
    @extend %h6;
    height: 3rem;
    line-height: 3rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  &:hover {
    background-color: darken($green, 20%);
    border-color: darken($green, 20%);
    color: $tan;
  }

  svg {
    margin-right: 0.75rem;
  }

  + .Button {
    margin-top: 1rem;
  }
}

.button-group {
  display: flex;

  .Button {
    margin-right: 1.5rem;
    margin-top: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &.wrap {
    flex-wrap: wrap;
    margin-bottom: -1.5rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    .Button {
      flex: 1;
      margin: 0 0.75rem 1.5rem;
    }
  }

  .Button.full-width {
    flex: 1;
  }
}
