@import '../styles/colors';
@import '../styles/typography';

.LoginPage {
  background-color: $brown;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .text-content {
    min-height: 100vh;
    margin: 0 auto;
    max-width: 48rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-controls {
    @extend %h5;
    min-height: 7rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      width: auto !important;
      padding: 0 3rem 0 2rem;
      display: flex !important;
      color: $brown !important;
      align-items: center;
    }

    p {
      @extend %p;

      a {
        text-decoration: underline;
      }
    }
  }

  .title {
    white-space: nowrap;
  }

  .light {
    color: $tan;
    font-size: 1rem;
    opacity: 0.5;

    a:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $phone-portrait-max) {
  .LoginPage {
    .login-controls {
      > div {
        padding: 0 2rem 0 1rem;
      }
    }
  }
}

