@import '../styles/colors';
@import '../styles/typography';

.RefreshBar {
  align-items: center;
  background-color: $brown;
  border-radius: 2rem;
  box-shadow: 0 4px 16px rgba($black, 0.1);
  color: $white;
  display: flex;
  display: none;
  left: 50%;
  padding: 0.5rem 1rem;
  position: fixed;
  top: 10rem;
  transform: translate3d(-50%, 0, 0);
  z-index: 10000;

  .Spinner {
    margin-right: 1rem;
  }

  &.refreshing {
    display: flex;
  }
}
