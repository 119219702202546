@import '../styles/colors';
@import '../styles/media_queries';

.NotFoundPage {
  background-color: $light-gray;
  padding-top: 9rem;

  > section {
    padding-bottom: 6rem;
  }

  .Button {
    margin: 1.5rem 0;
    max-width: 10rem;
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .NotFoundPage {
    padding-top: 6rem;
  }
}
