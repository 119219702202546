@import 'media_queries';

// Font weights
$bold: 700;
$semibold: 600;
$medium: 500;
$regular: 400;
$sans: 'Camber', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif: 'Kepler Std', Georgia, serif;

h1, %h1 {
  font-family: $serif;
  font-size: 4.5rem;
  font-weight: $regular;
  line-height: 1.11;
}

h2, %h2 {
  font-family: $serif;
  font-size: 2.5rem;
  font-weight: $medium;
  line-height: 1.33;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
}

h3, %h3 {
  font-family: $serif;
  font-size: 1.75rem;
  font-weight: $medium;
  line-height: 1.5;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

h4, %h4 {
  color: $brown;
  font-family: $sans;
  font-size: 1rem;
  font-weight: $semibold;
  line-height: 1.5;
}

h5, %h5 {
  font-family: $sans;
  font-size: 1.125rem;
  font-weight: $semibold;
  letter-spacing: 1.5px;
  line-height: 1rem;
  text-transform: uppercase;
}

h6, %h6 {
  font-family: $sans;
  font-size: 0.875rem;
  font-weight: $semibold;
  letter-spacing: 1px;
  line-height: 1.3;
  text-transform: uppercase;
}

p, %p, ol li {
  font-family: $sans;
  font-size: 1.25rem;
  font-weight: $regular;
  letter-spacing: 0;
  line-height: 2;
  margin-bottom: 1rem;
  text-transform: none;

}

p.small, %p-small {
  font-size: 1rem;
  line-height: 1.5;
}

.light, %light {
  opacity: 0.5;
}

a {
  text-decoration: none;
}

strong {
  font-weight: $medium;
}

@media screen and (max-width: $phone-landscape-max) {
  h1, %h1 {
    font-size: 2rem;
  }
}
