@import '../../styles/colors';
@import '../../styles/media_queries';

.EditProjectModal {
  background-color: $light-gray;
  margin: -3rem;
  padding: 3rem;

  h2 {
    text-align: center;
  }

  form {
    margin: 0 auto;
    width: 100%;
  }
}
